<template>
  <div class="d-flex justify-center align-center" style="height: 100%; overflow-y: hidden">
    <v-progress-circular
      :size="200"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import axiosClient from '@/plugins/axios'

export default {
  mounted() {
    localStorage.removeItem('state')
    var url = new URL(location.href)
    var code = url.searchParams.get('code')
    if (code === undefined) return
    let routeName = this.$route.name
    if (routeName == 'auth') {
      axiosClient
        .post(this.$API + '/auth/faceit', { code: code })
        .then(response => {
          console.log(response.data)
          var user = response.data

          localStorage.setItem('accessToken', user.token)
          delete user.token

          this.$store.dispatch('user/setUserData', user)
          this.$router.push('/')
          this.$root.vtoast.show('Login successful!')
        })
        .catch(e => {
          console.log(e)
          this.$router.push('/')
          this.$root.vtoast.show('Login failed!')
        })
    } else {
      let integrationType = routeName.split('-')[1]
      let challenge = localStorage.getItem('challenge')
      if (challenge == undefined) return
      localStorage.removeItem('challenge')
      axiosClient
        .post(this.$API + '/auth/' + integrationType, { code: code, challenge: challenge })
        .then(response => {
          this.$store.dispatch('user/setSocialintegrationId', { type: integrationType, id: response.data.id })
          if (response.data.username)
            this.$store.dispatch('user/setSocialintegrationUsername', {
              type: integrationType,
              username: response.data.username,
            })
          this.$router.push('/profile/edit/accounts')
          this.$root.vtoast.show('Account linking successful!')
        })
        .catch(e => {
          console.log(e)
          this.$router.push('/profile/edit/accounts')
          this.$root.vtoast.show('Account linking failure!')
        })
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
